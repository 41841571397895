/**
 * KeyboardEvent.keyCode の値
 * @type {Object}
 * @property {number} KEY_CODE.LEFT - →
 * @property {number} KEY_CODE.UP - ↑
 * @property {number} KEY_CODE.RIGHT - ←
 * @property {number} KEY_CODE.DOWN - ↓
 */
export const KEY_CODE = {
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40
}
