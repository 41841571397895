import { noop } from './_utility'

/**
 * DOM の描画を待って処理を行う
 * アニメーションの開始地点に DOM が描画されるのを待つために使う
 */
function nextFrame (fn) {
  const raf = window.requestAnimationFrame.bind(window)
  raf(() => raf(fn))
}

/**
 * CSS アニメーション
 * ハンドラ: beforeStart, start, end
 */
export function animateCSS (
  $el,
  {
    beforeStart = noop,
    start = noop,
    end = noop
  }
) {
  // 前回のアニメーションを強制的に終了させる
  const prevCb = $el.data('animate-css-cb')
  if (prevCb) {
    prevCb()
  }

  const cb = function cb (event) {
    if (event && $el[0] !== event.target) return
    $el.off('transitionend', cb)
    $el.data('animate-css-cb', null)
    end($el)
  }

  $el
    .data('animate-css-cb', cb)
    .css('transition-duration', '0s')
    .on('transitionend', cb)

  beforeStart($el)
  nextFrame(() => {
    $el.css('transition-duration', '')
    start($el)
  })
}

/**
 * CSS アコーディオンアニメーション
 * transition-property に height 指定必須
 */
export function animateAccordion (
  $target,
  isOpen,
  { before = noop, after = noop } = {}
) {
  if (isOpen) {
    let height
    animateCSS($target, {
      beforeStart: $el => {
        before()
        $el.css('height', '')
        height = $el.height()
        $el.height(0)
      },
      start: $el => $el.height(height),
      end: $el => {
        // Safari 対策で height の値を外すのは transition-duration を無効化してから
        // height を外すと 0 とみなしてアニメーションするような挙動をするため
        $el
          .css('transition-duration', '0s')
          .css('height', '')
        after()

        setTimeout(() => {
          $el.css('transition-duration', '')
        }, 0)
      }
    })
  } else {
    animateCSS($target, {
      beforeStart: $el => {
        before()
        $el.css('height', '')
        $el.height($el.height())
      },
      start: $el => $el.height(0),
      end: $el => {
        $el.css('height', '')
        after()
      }
    })
  }
}

/**
 * CSS フェードアニメーション
 * $target の要素の transition-property に opacity 指定必須
 *
 * @param {jQuery} $target - フェードさせる対象の要素
 * @param {boolean} isFadeIn - フェードインか否か
 * @param {object} options
 * @param {function} options.before - アニメーションの直前に実行されるコールバック
 * @param {function} options.after - アニメーションの直後に実行されるコールバック
 */
export function animateFade (
  $target,
  isFadeIn,
  { before = noop, after = noop } = {}
) {
  if (isFadeIn) {
    animateCSS($target, {
      beforeStart: $el => {
        before()
        $el.css('opacity', '0')
      },
      start: $el => $el.css('opacity', ''),
      end: () => after()
    })
  } else {
    animateCSS($target, {
      beforeStart: () => before(),
      start: $el => $el.css('opacity', '0'),
      end: $el => {
        $el.css('opacity', '')
        after()
      }
    })
  }
}

/**
 * 表示 / 非表示トランジションをするユーティリティ
 * コールバック内で CSS トランジションをトリガするような処理 (例えばクラスの追加) を行い、
 * そのトランジションの前、もしくは、後に要素の display プロパティを自動的に変える
 *
 * @param {JQuery} target - トランジションの対象
 * @param {boolean} isShow - 表示するか非表示するか
 * @param {function} fn - CSS トランジションを実行する関数。この中でクラスを変えるなどの処理を行う
 */
export function transitionShow ($target, isShow, fn) {
  if (isShow) {
    animateCSS($target, {
      start: fn,
      beforeStart: () => {
        $target.css('display', '')
      }
    })
  } else {
    animateCSS($target, {
      start: fn,
      end: () => {
        $target.css('display', 'none')
      }
    })
  }
}
