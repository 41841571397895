import { EventEmitter } from './_module'
import { debounce } from './_utility'

/**
 * リサイズの監視を行う
 */
export class ResizeVM extends EventEmitter {
  /**
   * @param {number} debounceMs - resize イベントを間引く秒数
   */
  constructor (debounceMs) {
    super()

    const onResize = () => {
      this.trigger('resize')
    }

    this.listener = debounceMs ? debounce(onResize, debounceMs) : onResize

    window.addEventListener('resize', this.listener)
  }

  /**
   * リサイズの監視をやめる
   */
  destroy () {
    window.removeEventListener('resize', this.listener)
  }
}
