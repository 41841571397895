/* ----------------------------------------
  pointer event
---------------------------------------- */

const deviceEvents = {
  Pointer: window.navigator.pointerEnabled,
  MSPointer: window.navigator.msPointerEnabled,
  Touch: typeof document.ontouchstart !== 'undefined'
}

/**
 * デバイスの対応イベントに応じて使用するイベント名を定義したオブジェクト
 * @type {Object}
 * @property {string} events.click - `click` と同等のイベント名
 * @property {string} events.down - `mousedown` と同等のイベント名
 * @property {string} events.move - `mousemove` と同等のイベント名
 * @property {string} events.up - `mouseup` と同等のイベント名
 * @property {string} events.enter - `mouseenter` と同等のイベント名
 * @property {string} events.leave - `mouseleave` と同等のイベント名
 */
let events = {
  click: 'click',
  down: 'mousedown',
  move: 'mousemove',
  up: 'mouseup',
  enter: 'mouseenter',
  leave: 'mouseleave'
}
if (deviceEvents.Pointer) {
  events = {
    click: 'click',
    down: 'pointerdown',
    move: 'pointermove',
    up: 'pointerup',
    enter: 'pointerenter',
    leave: 'pointerleave'
  }
} else if (deviceEvents.MSPointer) {
  events = {
    click: 'click',
    down: 'MSPointerDown',
    move: 'MSPointerMove',
    up: 'MSPointerUp',
    enter: 'MSPointerEnter',
    leave: 'MSPointerLeave'
  }
} else if (deviceEvents.Touch) {
  events = {
    click: 'click',
    down: 'touchstart',
    move: 'touchmove',
    up: 'touchend',
    enter: 'touchenter',
    leave: 'touchleave'
  }
}
export { events }

/**
 * event の起きた点の、クライアント内での X, Y 座標を取得する
 * @param {Event} e - event object
 * @return {{x: number, y: number}}
 */
export function getClientPos (e) {
  const eObj = e.changedTouches ? e.changedTouches[0] : e

  return {
    x: eObj.clientX,
    y: eObj.clientY
  }
}
