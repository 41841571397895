import $ from 'jquery'

import { enableSmoothScroll } from '@oro/static-site-modules'
import { ResizeVM } from '@oro/static-site-modules/_js/_resize'
import { arrangeHeight } from '@oro/static-site-modules/_js/_arrange_height.js'

/**
 * Navigation
 */
const headernav = () => {
  const nav = document.querySelector('#js-nav')
  const menu = document.querySelector('#js-menu')
  const header = document.querySelector('.vs-header')
  let closing = false

  nav.addEventListener('click', function () {
    if (closing) return

    nav.classList.toggle('o-open')
    header.classList.toggle('o-open')
    if (menu.classList.contains('o-open')) {
      menu.classList.add('o-close')
      closing = true
      setTimeout(() => {
        menu.classList.toggle('o-open')
        menu.classList.toggle('o-close')
        closing = false
      }, 300)
    } else {
      menu.classList.toggle('o-open')
    }
  })
}

function hasTouch () {
  return 'ontouchstart' in document.documentElement ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
}

if (hasTouch()) { // remove all :hover stylesheets
  try { // prevent exception on browsers not supporting DOM styleSheets properly
    for (var si in document.styleSheets) {
      var styleSheet = document.styleSheets[si]
      if (!styleSheet.rules) continue

      for (var ri = styleSheet.rules.length - 1; ri >= 0; ri--) {
        if (!styleSheet.rules[ri].selectorText) continue

        if (styleSheet.rules[ri].selectorText.match(':hover')) {
          styleSheet.deleteRule(ri)
        }
      }
    }
  } catch (ex) {}
}

/* ========================================
 * Document Ready
 * ========================================= */
$(() => {
  // スムーススクロール
  enableSmoothScroll()
  headernav()
  hasTouch()
  const resize = new ResizeVM(200)
  arrangeHeight('.js-arrange_height', resize)
  const currentPath = location.pathname
  const $menuitem = $('.header_nav_menu').find('a')
  $menuitem.each(function (i) {
    const url = $(this).attr('href')
    if (currentPath === url) {
      $(this).addClass('o-current')
      $(this).closest('.header_nav_menu_sub').prev().addClass('o-current')
    }
  })
  let $lang = $('.header_languages').find('.header_languages_anchor')
  let path = location.pathname
  let tempString
  const vn = '/vn'
  if (location.pathname.includes('/vn/')) {
    tempString = path.substring(3, path.length)
  } else {
    tempString = vn.concat(path)
  }
  $lang.attr('href', tempString)
})
