import $ from 'jquery'

/* ----------------------------------------
  ユーティリティ
---------------------------------------- */

/**
 * constant
 */
const BREAKPOINT = 768
const MAX_WIDTH = BREAKPOINT - 1

/**
 * 何もしない関数
 */
export const noop = () => {}

/** 常に true を返す関数 */
export const yes = () => true

/**
 * 現在 SP レイアウト（ウィンドウ幅がブレークポイント未満）かどうか
 * @return {boolean}
 */
export function isSP () {
  return window.matchMedia(`screen and (max-width: ${MAX_WIDTH}px)`).matches
}

/**
 * PC, SP レイアウト切り替わりの時にコールバックを実行する
 * @param {{ pc: function, sp: function }} options
 * @return {function(): void} 監視を止める関数
 */
export const onChangeLayout = (() => {
  const cbs = []

  window.matchMedia(`screen and (max-width: ${MAX_WIDTH}px)`).addListener(() => {
    if (window.matchMedia(`screen and (max-width: ${MAX_WIDTH}px)`).matches) {
      cbs.forEach(cb => cb.sp())
    } else {
      cbs.forEach(cb => cb.pc())
    }
  })

  return ({ pc = noop, sp = noop }) => {
    const listeners = { pc, sp }

    cbs.push(listeners)
    if (window.matchMedia(`screen and (max-width: ${MAX_WIDTH}px)`).matches) {
      sp()
    } else {
      pc()
    }

    return () => {
      const index = cbs.indexOf(listeners)
      if (index >= 0) {
        cbs.splice(index, 1)
      }
    }
  }
})()

/**
 * viewport 内に $el が入っているかどうか
 */
export function inViewport ($el) {
  const $window = $(window)
  const viewport = {
    left: $window.scrollLeft(),
    top: $window.scrollTop()
  }
  viewport.right = viewport.left + $window.width()
  viewport.bottom = viewport.top + $window.height()

  const el = $el.offset()
  el.right = el.left + $el.width()
  el.bottom = el.top + $el.height()

  return viewport.left <= el.left &&
    viewport.top <= el.top &&
    viewport.right >= el.right &&
    viewport.bottom >= el.bottom
}

/**
 * 渡した URL を新しいタブとして開く
 * @param {string} url
 */
export function openAsNewTab (url) {
  window.open(url)
}

/**
 * 軽量データ変更監視 (shallow)
 * obj のプロパティ key が更新されたときに fn を実行する
 */
export function watch (obj, key, fn) {
  let value = obj[key]

  const property = Object.getOwnPropertyDescriptor(obj, key)
  if (property && !property.configurable) {
    return
  }

  // 前の getter, setter を保持する
  const getter = property && property.get
  const setter = property && property.set

  Object.defineProperty(obj, key, {
    enumerable: true,
    configurable: true,

    get () {
      return getter ? getter.call(obj) : value
    },

    set (newValue) {
      const oldValue = getter ? getter.call(obj) : value
      if (oldValue === newValue) return

      if (setter) {
        setter.call(obj, newValue)
      } else {
        value = newValue
      }

      fn(newValue, oldValue)
    }
  })
}

/**
 * obj の値を fn で変換したオブジェクトを返す
 * @param {Object} obj
 * @param {function(value: any, key: string): any} fn
 * @return {Object} 変換後のオブジェクト
 */
export function mapValues (obj, fn) {
  const res = {}
  Object.keys(obj).forEach(key => {
    res[key] = fn(obj[key], key)
  })
  return res
}

/**
 * fn を ms ミリ秒に一度だけ実行する関数を返す
 * @param {function} fn
 * @param {number} ms
 */
export function debounce (fn, ms) {
  let timer = null

  return (...args) => {
    clearTimeout(timer)
    timer = setTimeout(() => fn(...args), ms)
  }
}

/**
 * 与えられた関数のすべてについて
 * ms ミリ秒に一度だけ実行されるようにする
 * @param {Object} obj - 値が関数のオブジェクト
 * @param {number} ms
 * @return {Object} - obj と同じ構造を持つオブジェクト
 */
export function debounceGroup (obj, ms) {
  let timer = null

  return mapValues(obj, fn => {
    return (...args) => {
      clearTimeout(timer)
      timer = setTimeout(() => fn(...args), ms)
    }
  })
}

/**
 * URL のクエリ文字列をパースしてオブジェクトを返す
 * @param {string} qs - クエリ文字列
 * @return {object} - パースされたクエリ文字列のオブジェクト
 */
export function parseParam (qs) {
  qs = qs.replace(/^\?/, '')
  return qs.split('&').reduce((acc, item) => {
    const pair = item.split('=')
    if (pair.length === 1) {
      acc[pair[0]] = true
    } else {
      acc[pair[0]] = pair[1]
    }
    return acc
  }, {})
}

/**
 * Object.assign Ponyfill
 * @param {...object} args
 * @return {object}
 */
export const assign = Object.assign || function (...args) {
  const res = args[0]
  args.slice(1).forEach(arg => {
    Object.keys(arg).forEach(key => {
      res[key] = arg[key]
    })
  })
  return res
}
