import $ from 'jquery'
import { isJQuery, toJQuery } from './_dom'

/**
 * 高さを揃えるためのオブジェクト
 */
export class HeightArranger {
  /**
   * @param {jQuery} $els - 高さを揃える対象の要素
   */
  constructor ($els) {
    this.els = $els.get().map(el => $(el))
  }

  /**
   * 高さ揃えをリセットする
   */
  reset () {
    this._toJQuery(this.els).css('height', '')
  }

  /**
   * 高さを揃える対象の要素を差し替える
   * @params {Element[]} els
   */
  replaceElements (els) {
    this.els = els.map(el => $(el))
  }

  /**
   * 高さ揃えを再計算する
   */
  arrange () {
    this.reset()
    this._arrangeImpl(this.els)
  }

  _arrangeImpl (els) {
    if (els.length === 0) return

    els = this._sortByTop(els)

    const acc = [els[0]]
    const top = acc[0].offset().top

    for (let i = 1; i < els.length; ++i) {
      const $next = els[i]
      if (top !== $next.offset().top) break

      acc.push($next)
    }

    // 要素が2つ以上の時は高さを指定する
    if (acc.length > 1) {
      const heights = acc.map($el => parseFloat($el.css('height'), 10))
      const maxHeight = Math.max(...heights)
      this._toJQuery(acc).css('height', maxHeight + 'px')
    }

    this._arrangeImpl(els.slice(acc.length))
  }

  _sortByTop (els) {
    return els
      .map($el => ({
        $el,
        top: $el.offset().top
      }))
      .sort((a, b) => a.top - b.top)
      .map((item) => item.$el)
  }

  _toJQuery (arr) {
    return arr.reduce((acc, el) => acc.add(el), $())
  }
}

/**
 * 指定した selector から取得できる要素について
 * 0. top の低い順に要素をソート
 * 1. 要素の前の方から top が同じ Y 軸座標の要素を取り出す
 * 2. グループ G 内で最も高い要素の高さ H を取得する
 * 3. G 内のすべての要素の高さを H にする
 * 4. 0-3 を要素がなくなるまで繰り返す
 *
 * resizeVm が渡されている時はリサイズの監視を行う
 *
 * @param {string|Element[]|JQuery} selectorOrEls - 高さを揃えたい要素のセレクター、または、DOM 要素
 * @param {ResizeVM} [resizeVm]
 */
export function arrangeHeight (selectorOrEls, resizeVm) {
  let all = selectorOrEls
  if (typeof all === 'string') {
    all = $(all)
  } else if (!isJQuery(all)) {
    all = toJQuery(all)
  }

  const vm = new HeightArranger(all)
  vm.arrange()

  if (resizeVm) {
    resizeVm.on('resize', () => vm.arrange())
  }

  return vm
}
