import $ from 'jquery'

/* ----------------------------------------
  DOM 操作
---------------------------------------- */

/**
 * forEach が使える elements を取得する
 * @param {string} selector - カンマで区切られたひとつ以上の CSS セレクタグループ文字列 (querySelectorAll の引数と同じ)
 * @param {Element} context - この element の中の要素だけを対象とする
 * @return {Element[]} `selector` にマッチした element の配列
 */
export function getElements (selector, context = document) {
  const nodeList = context.querySelectorAll(selector)
  return nodeList ? Array.prototype.slice.call(nodeList, 0) : []
}

/**
 * jQuery から DOM に変換
 * @param {JQuery} $els
 * @return {Element[]}
 */
export function toDom ($els) {
  return $els.get()
}

/**
 * DOM から jQuery に変換
 * @param {Element[]} els
 * @return {JQuery}
 */
export function toJQuery (els) {
  return els.reduce((acc, el) => {
    return acc.add(el)
  }, $())
}

/**
 * jQuery オブジェクトかどうかを判別する
 * http://api.jquery.com/jquery-2/
 * @param {any} el
 * @return {boolean} jQuery オブジェクトなら true
 */
export function isJQuery (el) {
  return typeof el.jquery === 'string'
}
