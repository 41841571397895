import $ from 'jquery'

import { watch } from './_utility.js'
import { EventEmitter } from './_module.js'

/**
 * constant
 */
const DURATION = 600
const EASING = 'easeInOutCubic'

/**
 * ブラウザデフォルトのスクロールターゲットの jQuery オブジェクトを取得
 */
export function getDefaultScrollTarget () {
  return $(document.scrollingElement || document.documentElement)
}

/**
 * 指定した jQuery オブジェクトに対応する要素へスムーススクロールする
 * 初期値はページトップ
 */
export function scrollToSmoothly ($to, duration = DURATION) {
  const pos = (!$to || $to.length === 0) ? 0 : getPos($to)

  // Fixed な header の高さを考慮する
  const fixedPos = pos - (parseInt($('#js-main_wrapper').css('padding-top'), 0) || 0)

  getDefaultScrollTarget().animate({
    scrollTop: fixedPos
  }, {
    duration,
    easing: EASING
  })
}

/**
 * アニメーションなしでスクロール位置を移動する
 */
export function scrollTo ($to) {
  scrollToSmoothly($to, 0)
}

/**
 * アンカーリンクのスムーススクロールを有効にする
 * href が
 * 1. '#' ならページトップへスクロール
 * 2. '#xxx' で該当IDが存在すればその要素へスクロール
 * 3. '#top' ならページトップへスクロール
 * 4. それ以外なら何もしない
 */
export function enableSmoothScroll (selector = 'a[href^="#"]') {
  $(document).on('click', selector, event => {
    const to = event.currentTarget.getAttribute('href')

    if (to === '#') {
      event.preventDefault()
      scrollToSmoothly()
      return
    }

    const $to = $(to)
    if ($to.length > 0) {
      scrollToSmoothly($to)
      return
    }

    if (to === '#top') {
      event.preventDefault()
      scrollToSmoothly()
    }
  })
}

/**
 * jQuery オブジェクトの位置情報を返す
 * $obj に jQuery オブジェクト指定必須
 */
function getPos ($obj) {
  const pos = $obj.offset().top
  return pos
}

/**
 * スクロール監視
 */
export class ScrollVM extends EventEmitter {
  constructor ($target) {
    super()

    this.data = {
      offset: {
        top: $target.scrollTop(),
        left: $target.scrollLeft()
      }
    }

    this.bind(this.data, $target)
  }

  /** @private */
  bind (data, $target) {
    $target.on('scroll', () => {
      this.set($target.scrollLeft(), $target.scrollTop())
    })

    watch(data, 'offset', newValue => {
      this.trigger('scroll', newValue)
    })
  }

  /** @private */
  set (left, top) {
    this.data.offset = {
      top,
      left
    }
  }

  get () {
    return this.data.offset
  }
}
