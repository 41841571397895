import $ from 'jquery'

import { watch, isSP, openAsNewTab } from './_utility'
import { animateFade } from './_animation'

/**
 * モーダル
 *
 * @example
 * const modal = new ModalVM($body)
 *
 * modal.show(id) // id をテンプレートとしてモーダルを開く
 * modal.hide() // 表示されているモーダルを非表示にする。
 */
export class ModalVM {
  /**
   * @param {jQuery} $body - HTML の body 要素に紐づく jQuery オブジェクト
   */
  constructor ($body) {
    /** @private */
    this.$body = $body

    const $backdrop = $(
      '<div class="modal_backdrop" aria-hidden="true"></div>'
    ).appendTo($body)

    const $modal = $(`
    <div class="js-modal modal">
      <div class="js-modal_content modal_content"></div>
    </div>
   `)

    const $close = $(
      '<button class="js-modal_close modal_close" aria-label="閉じる"></button>'
    )

    /** @private */
    this.data = {
      showingId: null,
      classList: []
    }

    this.bind(this.data, $backdrop, $modal, $close)
  }

  /**
   * データと DOM を結びつける
   * @private
   */
  bind (data, $backdrop, $modal, $close) {
    const $content = $modal.find('.js-modal_content')
    const templateClass = 'js-modal_template'
    const basicContentClass = ['js-modal_content', 'modal_content']

    $(document).on('click', '.js-modal', event => {
      if (event.target !== $modal[0]) return
      this.hide()
    })

    $(document).on('click', '.js-modal_close', () => {
      this.hide()
    })

    /**
     * 指定した ID の要素からテンプレートを取得し、モーダルのコンテンツにする
     * フェードインでモーダルを表示する。
     * また、テンプレートの要素に追加のクラスが指定されている場合、
     * それをモーダルのコンテンツ要素にマージする。
     * ID が指定されなかった場合、モーダルを隠す。
     */
    watch(data, 'showingId', id => {
      // モーダルを閉じた時
      if (id === null) {
        this.fadeOut($backdrop, $modal)
        return
      }

      const $template = $('#' + id)
      if ($template.length === 0) return

      // テンプレートの追加クラスを抽出する
      const classList = $template
        .attr('class').split(' ')
        .filter(value => value !== templateClass)
      this.setClassList(classList)

      // 前のモーダルのコンテンツを削除
      $content.children().remove()

      // 指定された ID のコンテンツをクローンしてモーダルに追加
      $content
        .append($close)
        .append($template.clone().children())

      // 表示
      this.fadeIn($backdrop, $modal)
    })

    /**
     * モーダルのコンテンツ部分にクラスを追加する
     */
    watch(data, 'classList', list => {
      const classList = basicContentClass.concat(list)
      $content.attr('class', classList.join(' '))
    })
  }

  /** @private */
  fadeIn ($backdrop, $modal) {
    animateFade($backdrop, true, {
      before: () => $backdrop.attr('aria-hidden', 'false')
    })
    animateFade($modal, true, {
      before: () => {
        this.preventScroll()
        this.$body.append($modal)
      }
    })
  }

  /** @private */
  fadeOut ($backdrop, $modal) {
    animateFade($backdrop, false, {
      after: () => $backdrop.attr('aria-hidden', 'true')
    })
    animateFade($modal, false, {
      after: () => {
        this.allowScroll()
        $modal.remove()
      }
    })
  }

  /**
   * html 本文のスクロールを抑制する
   * スクロールバーが消えた分横にずれるため、padding-right を追加する
   * @private
   */
  preventScroll () {
    const $html = $('#js-html')
    const scrollbarWidth = window.innerWidth - $html.width()
    $html.addClass('o-modal_opened')
      .css('padding-right', scrollbarWidth)
  }

  /** @private */
  allowScroll () {
    $('#js-html')
      .removeClass('o-modal_opened')
      .css('padding-right', '')
  }

  /** @private */
  setClassList (list) {
    this.data.classList = list
  }

  /**
   * id に相当する要素をテンプレートとして、モーダルを表示する。
   * その時、そのテンプレートに付与された class を modal_content に追加する
   * @param {string} id - モーダルのテンプレートの ID
   */
  show (id) {
    // data-modal-sp-url に値があり、SP の時、
    // モーダルの代わりにその値の URL を新規タブで開く
    if (isSP()) {
      const $template = $('#' + id)
      const spURL = $template.attr('data-modal-sp-url')
      if (spURL) {
        openAsNewTab(spURL)
        return
      }
    }
    this.data.showingId = id
  }

  /**
   * 表示されているモーダルを非表示にする。
   */
  hide () {
    this.data.showingId = null
  }
}

/**
 * HTML 内に存在するすべてのモーダルを有効化する
 * `.js-modal_trigger` が付与されている要素が対象
 */
export function enableAllModal () {
  const modal = new ModalVM($('body'))

  // data-modal-template-next が true のとき、trigger の次の要素をテンプレートとする
  let uid = 0
  $('.js-modal_trigger[data-modal-template-next="true"]').each((i, el) => {
    const $trigger = $(el)
    const $template = $trigger.next()

    uid += 1
    const id = `js-modal_auto_assigned_id_${uid}`
    $trigger.attr('href', '#' + id)
    $template.attr('id', id)
  })

  $(document).on('click', '.js-modal_trigger', event => {
    event.preventDefault()
    event.stopImmediatePropagation()

    const id = $(event.currentTarget).attr('href')
    if (id[0] !== '#' || id.length <= 1) return

    modal.show(id.slice(1))
  })
}
